import { useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { casinoBet } from "../../Redux/Slices/casinobetSlice";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { toast } from "react-toastify";
// import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { fetchBetButton } from "../../Redux/Slices/betButtonSlice";

const Modal = ({ betDetails, setbetDetails, setShowModal, name, id }) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const { buttonData } = useSelector((state) => state.buttonData);
  // console.log("betDetails==>", betDetails, name, id );
  const inputRef = useRef();
  const handleChange = (event) => {
    let formattedValue = DOMPurify.sanitize(
      event.target.value.replace(/[^0-9]/g, "").slice(0, 8)
    );
    handleStake(formattedValue);
  };

  const handleStake = (value) => {
    setbetDetails({
      ...betDetails,
      userid: uid,
      amount: value,
      winAmount: 0,
    });
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    dispatch(fetchBetButton({ id: uid, token }));
  }, [dispatch, uid]);
  const handlePlacebet = async () => {
    try {
      delete betDetails.nat;
      // console.log("bet", betDetails);
      let responce = await dispatch(casinoBet({ betDetails, token })).unwrap();
      // console.log("placebet", responce);
      if (responce.data.result == "Place Bet Successfull") {
        toast.success(responce.data.result);

        // dispatch(fetchuserBalance({ id: uid, token }));
        if (
          betDetails.gameid == 1 ||
          betDetails.gameid == 2 ||
          betDetails.gameid == 3 ||
          betDetails.gameid == 4 ||
          betDetails.gameid == 5
        ) {
          dispatch(
            fetchThirtyTwoCardStack({ uid, gameId: betDetails.gameid, token })
          );
        }
        // dispatch(fetchCasinobetHistory({ uid, token, id }));
      } else {
        toast.error(responce.data.result);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setShowModal(false);
      setbetDetails({});
    }
  };
  return (
    <>
      <div
        onClick={() => {
          setShowModal(false);
        }}
        className="data-modal-wrapper"
      ></div>
      <div className="data-modal-container">
        <div className="head-border d-flex align-items-center">
          <p className="my-auto mx-2">{name}</p>
        </div>
        <div className="container-body">
          <p className="my-1 mx-2">{betDetails.nat}</p>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Odds</p>
            </div>
            <div className="col-8">
              <button className="modal-w-btn">{betDetails.multipler}</button>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Stake</p>
            </div>
            <div className="col-8">
              {/* <button className="modal-w-btn">{betDetails.amount}</button> */}
              <input
                type="text"
                class="modal-w-btn text-center"
                value={betDetails.amount}
                max={100000}
                min="0"
                maxlength="6"
                inputmode="numeric"
                onInput={handleChange}
                ref={inputRef}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Profit</p>
            </div>
            <div className="col-8">
              <button className="modal-g-btn">19.5</button>
            </div>
          </div> */}
          <div className="orange-buttons-row d-flex flex-wrap">
            {buttonData.map((item, index) => (
              <button
                className="orb mx-1 my-1"
                key={index}
                onClick={() => {
                  handleStake(item.price_value);
                }}
              >
                {item.price_level}
              </button>
            ))}
           
          </div>
        </div>
        <div className="modal-footer row mt-1">
          <div className="col-5"></div>
          <div className="col-7 py-2">
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="mcb "
            >
              Cancel
            </button>
            <button className="mbb mx-1" onClick={() => handlePlacebet()}>
              Place Bet
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
