import React, { useState, useEffect } from "react";
import logo1 from "../../Media/logo.png";
import "./Navbar.css";

import { BsFillPersonFill } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdLeaderboard } from "react-icons/md";
import { IoMdSwap } from "react-icons/io";
import { FaCreditCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { IoMdTrendingUp } from "react-icons/io";
import { MdOutlineLegendToggle } from "react-icons/md";
import { FaKey } from "react-icons/fa";
import StakeSettingModal from "./StakeSettingModal";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { toast } from "react-toastify";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
function Navbar({ toggleSidebar, showSidebar }) {
  // const { userBalance } = useSelector((state) => state.userBalanceData);
  const uid = sessionStorage.getItem("userData");
  const uName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");

  // useEffect(() => {
  //   dispatch(fetchuserBalance({ id: uid, token }));
  // }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(window.location.pathname);
  const SOCKET_URL = "https://inplay-socket-api.nakshtech.info/";

  const displayModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      let res = await API.post(
        "/UserLogout",
        {
          uid: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("res", res);
      if (res.data.data === "Logout successfull") {
        toast.success(res.data.data);
      } else {
        toast.error(res.data.data);
      }
    } catch (e) {
      console.log("Eroor", e);
    } finally {
      sessionStorage.clear();
      dispatch(logoutUser());
    }
  };

  useEffect(() => {
    // Update active link on route change

    const path = location.pathname;

    if (path === "/") {
      setActive("/");
    } else if (path === "/InPlay") {
      setActive("/InPlay");
    } else if (path === "/LiveCasino") {
      setActive("/LiveCasino");
    }
  }, [location.pathname]);

  const [userBalance, setBalance] = useState({});
  useEffect(() => {
    if (uid) {
      const cricketMatchesSocket = io(SOCKET_URL);
      cricketMatchesSocket.emit("GetWalletExposureSocket", uid);
      cricketMatchesSocket.on("GetWalletExposureSocket_FromAPI", (data) => {
        // console.log("userbalance",data[0][0])
        setBalance(data[0][0]);
      });

      return () => {
        cricketMatchesSocket.disconnect();
      };
    }
  }, [uid]);

  return (
    <nav className="navbar py-0  navbar-b d-block navbar-expand-lg navbar-dark bg-black">
      <div className="">
        <div className="row">
          <div className=" col-7 col-lg-3 col-sm-6  order-lg-1 order-2 ">
            <a className="navbar-brand" href="">
              <img className="navbar-brand ms-3" src={logo1} alt="logo" />
            </a>
          </div>
          <div className="col-lg-6  order-lg-2    d-lg-block  d-none ">
            <div className="d-flex justify-content-center pt-2 ">
              <NavLink
                onClick={() => {
                  setActive("/");
                }}
                to="/"
                className={` nav-btn ${active === "/" && "is-active"}`}
              >
                SPORTS
              </NavLink>
              <NavLink
                onClick={() => {
                  setActive("/InPlay");
                }}
                className={` nav-btn ${active === "/InPlay" && "is-active"}`}
                to="/InPlay"
              >
                INPLAY
              </NavLink>
              <NavLink
                onClick={() => {
                  setActive("/LiveCasino");
                }}
                to="/LiveCasino"
                className={` nav-btn ${
                  active === "/LiveCasino" && "is-active"
                }`}
              >
                LIVE TABLE{" "}
              </NavLink>
            </div>
          </div>
          <div className="col-3 col-lg-3  col-md-3 col-sm-3 order-lg-3 order-3  order-xsm-3 d-flex justify-content-end  ">
            {token ? (
              <>
                <span
                  className="white-space-nowrap mt-2"
                  style={{ fontSize: "x-small" }}
                >
                  <span style={{ color: "white" }}>PTS: </span>
                  {parseFloat(userBalance?.wallet_amount || 0).toFixed(2)}
                  <br />
                  <span style={{ color: "white" }}>EXP: </span>
                  {userBalance?.exp_amount &&
                    userBalance?.exp_amount > 0 &&
                    "-"}{" "}
                  {parseFloat(userBalance?.exp_amount || 0).toFixed(2)}
                  <br />
                  <span style={{ color: "white" }}>User: </span>
                  {uName}
                </span>
                &nbsp;&nbsp;
                <div
                  className="dropdown mt-0"
                  onClick={() => setShow(!show)}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                >
                  <button className="dropbtn mt-2 pt-0 d-flex">
                    <div className="person-icon-div">
                      <BsFillPersonFill className="person-icon" />
                    </div>
                    <IoMdArrowDropdown className="mt-2" />
                  </button>
                  <div
                    className="dropdown-content"
                    style={{ display: show ? "block" : "none" }}
                  >
                    <NavLink to="/profile" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        PROFILE
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink>
                    {/* <NavLink to="/Deposit">
                    <div className="d-flex justify-content-between">
                      DEPOSIT
                      <FaCreditCard />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/DepositHistory">
                    <div className="d-flex justify-content-between">
                      DEPOSIT HISTORY
                      <FaCreditCard />{" "}
                    </div>
                  </NavLink> */}
                    <NavLink
                      to="/WithdrawalUSDT"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        WITHDRAWAL
                        <FaCreditCard />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/WithdrawalHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        WITHDRAWAL HISTORY <FaList />{" "}
                      </div>
                    </NavLink>
                    <NavLink
                      to="/BettingHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        BETTING HISTORY
                        <MdLeaderboard />{" "}
                      </div>
                    </NavLink>
                    <NavLink to="/ProfitLoss" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        PROFIT-LOSS
                        <MdOutlineLegendToggle />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/AccountStatement"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        ACCOUNT STATEMENT <MdPayments />{" "}
                      </div>
                    </NavLink>
                    <NavLink
                      to="/TransactionHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content- between">
                        TRANSACTION HISTORY
                        <IoMdTrendingUp />
                      </div>
                    </NavLink>
                    <NavLink to="/MyMarkeet" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between ">
                        Unsettled Bet
                        <MdLeaderboard />
                      </div>
                    </NavLink>

                    <NavLink to="/FundsTransfer" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        FUND TRANSFER
                        <IoMdSwap />
                      </div>
                    </NavLink>

                    {/* <NavLink>
                  <div
                    onClick={() => displayModal()}
                    className="d-flex justify-content-between">
                    STAKE SETTING
                    <MdOutlineMemory />
                  </div>
                </NavLink> */}
                    {modal && <StakeSettingModal closeModal={closeModal} />}
                    <NavLink to="/CasinoResults" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        CASINO RESULTS
                        <MdLeaderboard />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/ChangePassword"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        CHANGE PASSWORD
                        <FaKey />
                      </div>
                    </NavLink>
                    <NavLink to="/ButtonValue" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Set Button Values
                        <FaKey />
                      </div>
                    </NavLink>
                    <NavLink onClick={handleLogout} to="/">
                      <div className="d-flex justify-content-between">
                        LOGOUT
                        <FaKey />
                      </div>
                    </NavLink>
                  </div>
                </div>
              </>
            ) : (
              <div className="">
                <NavLink to="/Login">
                  <button className="signin-btn mt-2  me-3">SignIn</button>
                </NavLink>
                {/* <NavLink to="/WalletSignIn">
                <button className="wallet-btn mt-2  me-3">
                  Wallet Sign...
                </button>
              </NavLink> */}
              </div>
            )}
          </div>
          <div className=" col-2 col-lg-3 col-sm-3   order-lg-4 order-1 ">
            {/* Toggler/collapsible Button */}
            <button
              className="navbar-toggler mt-2 border-none"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Navbar links */}
            <div
              className={`collapse navbar-collapse${
                showSidebar ? " show" : ""
              }`}
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  {/* <a className="nav-link" href="#">Link</a> */}
                </li>
                {/* Add more navbar links here if needed */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
