import axios from "axios";

const baseURL = "https://inplay-api.nakshtech.info/";

const betHistoryAPI = axios.create({
  baseURL,
});

export const fetchBetHistory = async (data, token) => {
  try {
    const response = await betHistoryAPI.post('BetHistory', data, {
      headers: {
        Authorization: token,
      },
    });
    console.log("bethistroy", response );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default betHistoryAPI;
