import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBetHistory } from '../api/betHistoryAPI';

const initialState = {
  betHistory: [],
  isLoading: false,
  invalidToken: false,
  error: null,
};

export const fetchBetHistoryData = createAsyncThunk(
  'betHistory/fetchBetHistoryData',
  
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await fetchBetHistory(data, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const betHistorySlice = createSlice({
  name: 'betHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBetHistoryData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBetHistoryData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.betHistory = action.payload;
        state.error = null;
      })
      .addCase(fetchBetHistoryData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload?.message === "Invalid authentication token") {
          state.invalidToken = true;
        }
      });
  },
});

export default betHistorySlice.reducer;
