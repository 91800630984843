export const Loader=()=>{
    return(<>
        <div className="" style={{position:'relative'}}>
         <img style={{width:"100%",height:"18rem"}} src="https://ss247.life/api/e8ade088fdab6e7c6e5133ec4afe45ac36bbe5b4/Loader.gif" alt="GIF" />
         <div className="text-white mt-2 fw-bold position-absolute" style={{top:'60%',left:'43%'}}>
              {" "}
              PLEASE WAIT... 
            </div>
    </div>
    </>)
}