import React from "react";
import OddsTable from "./Table/OddsTable";

const MatchOdds = () => {
  return (
    <div className="row  bg-dark ">
      <div className="Match-ods-h d-flex justify-content-between align-items-center">
        <p className="mx-2 mt-3 d-flex">
          MATCH ODDS <div className="green-live-dot ms-2 mt-2"></div>
        </p>
        <p className="mx-2 mt-3">Maximum Bet 0</p>
      </div>
      <div className="row back-lay-row  bg-dark ">
        <div className="col-7 "></div>
        <div className="col-5 d-flex">
          <button className="back ">Back</button>
          <button className="lay ms-1">Lay</button>
        </div>
      </div>
      <div className="betting-team-info">
        <>
          <OddsTable tabName="" runnerName="" back="" lay="" sus="" />
        </>
      </div>
    </div>
  );
};

export default MatchOdds;
