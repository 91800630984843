import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchuserCashout } from "../../Redux/Slices/cashOutSlice";
import { useParams } from "react-router-dom";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import axios from "axios";

const baseURL = "https://battlemoney-match-api.nakshtech.info/";

const API = axios.create({
  baseURL,
});

const CashOut = ({
  // SureButton,
  calculateLiability,
  Cashout,
  setShowCashOut,
  // handleSure,
  // handleCashoutYes,
  // cashoutloader,
  // setCashOutloader,
}) => {
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("userData");
  // console.log("id",userId)
  const token = sessionStorage.getItem("token");
  const [SureButton, setSureButton] = useState(true);
  const [cashoutloader, setCashOutloader] = useState(false);
  const [myBet, setmyBet] = useState();
  // setBetHistory
  const { id } = useParams();

  function handleSure(bool) {
    setSureButton(bool);
  }

  const handleCashoutYes = async () => {
    const liability = calculateLiability();
    const cashOut1 = parseFloat(Cashout).toFixed(2);
    const balance = calculateLiability() + Cashout;

    try {
      const action = await dispatch(
        fetchuserCashout({ userId, token, id, liability, cashOut1, balance })
      );

      // Access the payload from the action
      const response = action.payload;

      // console.log("cashout", response);

      if (response === "success") {
        toast.success(response);
        setSureButton(true);
        setShowCashOut(false);
        dispatch(
          fetchbetStackData({
            userId,
            mId: id,
            matchType: "Match Odds",
            token,
          })
        );

        dispatch(
          fetchbetStackData({
            userId,
            mId: id,
            matchType: "Match Odds",
            token,
          })
        );
        dispatch(fetchuserBalance({ id: userId, token }));
      } else {
        console.error("Cashout failed:", response);
      }
    } catch (error) {
      // Handle errors if needed
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   CashoutPankaj_API();
  // }, []);

  // const CashoutPankaj_API = async () => {
  //   try {
  //     console.log('jjjjjj');
  //     let res = await API.post(
  //       `/MatchOddsData`,
  //       {uid: 'BTMASU544',
  //       matchid: '33146686',
  //       mname:"Match Odds"
  //       },
  //       {
  //         headers: {
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     console.log('jjjjjj',res.data);
  //     const data = res.data.data[0];
  //     if (data.length > 0) {
  //       setmyBet(data);
  //     }
  //   } catch (e) {
  //     console.log("Error While Fetch AvbetCancle_API", e);

  //   }
  // };

  return (
    <>
      {SureButton ? (
        <>
          <button className="btnn btnn-dark">
            Liability:
            {parseFloat(calculateLiability() || 0).toFixed(2)}
          </button>
          <button
            className={`btnn ${Cashout < 0 ? "btnn-red" : "btnn-green"}`}
            onClick={() => handleSure(false)}
          >
            CashOut:
            {parseFloat(Cashout || 0).toFixed(2)}
          </button>
          <button className="btnn btnn-dark">
            Balance:{" "}
            {(parseFloat(calculateLiability() + Cashout) || 0).toFixed(2)}
          </button>
        </>
      ) : (
        <div
          id="sure_div"
          style={{
            background: "inherit",
            borderRadius: "10px",
            padding: "2px 12px 2px 17px",
            border: "1px solid #ffffff",
            width: "360px",
            color: "#fff",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontWeight: "normal",
              fontSize: "13px",
            }}
          >
            Are you sure you want to cashout this amount?
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <button
              className="button"
              type="button"
              id="cashout_no_web"
              style={{
                marginRight: "2px",
                float: "none",
                height: "30px",
                color: "white",
                background: "#ff5000",
                fontSize: "13px",
                border: "0px solid white",
                padding: "2px",
                borderRadius: "4px",
                width: "75px",
              }}
              onClick={() => handleSure(true)}
            >
              NO
            </button>
            <button
              className="button"
              type="button"
              id="cashout_yes_web"
              style={{
                marginRight: "2px",
                float: "none",
                height: "30px",
                color: "white",
                background: "#4caf50",
                fontSize: "13px",
                border: "0px solid white",
                padding: "2px",
                borderRadius: "4px",
                width: "75px",
              }}
              onClick={() => {
                handleCashoutYes();
              }}
              disabled={cashoutloader ? true : false}
            >
              YES
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CashOut;
