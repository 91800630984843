import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import betStackSlice from "../../../Redux/Slices/betStackSlice";
import { fetchbetStackData } from "../../../Redux/api/betStackAPI";

function TiedTable({
  tabName,
  runnerName,
  back,
  lay,
  sus,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  setDataValue,
  stakefunction,
}) {
  const { id, mktId } = useParams();

  const dispatch = useDispatch();
  const [odd1Stack, setOdd1Stack] = useState("0");
  const [subBetDetails, setsubBetDetails] = useState({});
  const handleClick = (betDetails, bettype, marketType, value) => {
    // console.log("click", value);
    handleModal(true);
    setRealtimeData(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    // setsubBetDetails(betDetails);
    setDataValue(value);
  };
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);

  const { oddStack, loading } = useSelector((state) => state.betStack);
  // console.log("oddStack", oddStack);

  useEffect(() => {
    if (!loading) {
      if (tabName?.runnerName == oddStack[0]?.team) {
        setOdd1Stack(oddStack[0]?.amount);
        stakefunction != "none" && stakefunction(oddStack[0]?.amount);
      } else if (tabName?.runnerName == oddStack[1]?.team) {
        setOdd1Stack(oddStack[1]?.amount);
        stakefunction != "none" && stakefunction(oddStack[1]?.amount);
      } else if (tabName?.runnerName == oddStack[2]?.team) {
        setOdd1Stack(oddStack[2]?.amount);
        stakefunction != "none" && stakefunction(oddStack[2]?.amount);
      }
    }
  }, [dispatch, loading]);

  // console.log("odd1Stack", odd1Stack);
  return (
    <div className="row">
      <div className="col-7 betting-t-n ">
        <p className="ms-2 mb-0 d-flex ">
          {tabName?.runnerName}
          {/* (<span className="favourite">favourite</span>) */}
        </p>
        <p className={`ms-3  ${odd1Stack > 0 ? "success-1" : "zero"}`}>
          {odd1Stack}
        </p>
      </div>
      <div className="col-5 d-flex">
        <div className="blue-bet-btn mb-0">
          <div
            className="d-flex flex-column align-items-center"
            onClick={() =>
              handleClick(
                {
                  marketId: mktId,
                  evntid: eventTypeid,
                  matchid: id,
                  selectionId: sus.selectionId,
                  runnerName: tabName.runnerName,
                  handicap: 0,
                  status: sus.status,
                  lastPriceTraded: 0,
                  totalMatched: 0,
                  Backprice1: back[0].price,
                  Backprice: back[0].price,
                  Backsize: back[0].size,
                  Layprice1: 0,
                  Layprice: 0,
                  Laysize: 0,
                  min: 0,
                  max: 0,
                  mname: "Tied Match",
                  betRunnerName: tabName.runnerName,
                },
                "back",
                "Tied Match",
                { valueOf: back[0]?.price, selId: sus.selectionId }
              )
            }
          >
            {back[0]?.price}
            <span>
              {back[0]?.size < 1000
                ? back[0]?.size
                : (back[0]?.size / 1000).toFixed(1) + "K"}
            </span>
          </div>
        </div>
        <div className=" orange-bet-btn ms-1 mb-0">
          <div
            className="d-flex flex-column align-items-center"
            onClick={() =>
              handleClick(
                {
                  marketId: mktId,
                  evntid: eventTypeid,
                  matchid: id,
                  selectionId: sus.selectionId,
                  runnerName: tabName.runnerName,
                  handicap: 0,
                  status: sus.status,
                  lastPriceTraded: 0,
                  totalMatched: 0,
                  Backprice1: 0,
                  Backprice: 0,
                  Backsize: 0,
                  Layprice1: lay[0].price,
                  Layprice: lay[0].price,
                  Laysize: lay[0].size,
                  min: 0,
                  max: 0,
                  mname: "Tied Match",
                  betRunnerName: runnerName,
                },
                "lay",
                "Tied Match",
                { valueOf: lay[0]?.price, selId: sus.selectionId }
              )
            }
          >
            {lay[0]?.price}
            <span>
              {" "}
              {lay[0]?.size < 1000
                ? lay[0]?.size
                : (lay[0]?.size / 1000).toFixed(1) + "K"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TiedTable);
