import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://inplay-api.nakshtech.info/",
});

const encryptdata = async (payload) => {
  try {
    let res;

    let response = await API.get("getPublicKey");
    let publicKey = response.data.publicKey;
    if (publicKey) {
      const data = JSON.stringify(payload);

      const textBuffer = new TextEncoder().encode(data);

      const binaryString = window.atob(publicKey);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const cryptoKey = await window.crypto.subtle.importKey(
        "spki",
        bytes,
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        true,
        ["encrypt"]
      );
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        cryptoKey,
        textBuffer
      );
      // Convert encryptedData to base64
      const base64EncryptedData = btoa(
        String.fromCharCode(...new Uint8Array(encryptedData))
      );

      return base64EncryptedData;
    }
  } catch (e) {
    console.log("encrypt Api error:", e);
  }
};

export const placeBet = createAsyncThunk(
  "bet/placeBet",
  async ({ betDetails, marketType, token },{rejectWithValue}) => {
    let Url =
      marketType === "Match Odds" ||
      marketType === "Bookmaker Market" ||
      marketType === "Tied Match"
        ? "/PlaceBetBook"
        : marketType === "Session Market"
        ? "PlaceBet"
        : "/";
    try {
      const body = await encryptdata(betDetails);
      const response = await API.post(
        Url,
        {
          encryptedData: body,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("bet Res", response);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const betSlice = createSlice({
  name: "bet",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(placeBet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(placeBet.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(placeBet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default betSlice.reducer;
