import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
function BookTable({
  tabName,
  runnerName,
  back,
  lay,
  sus,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  setDataValue,
  sessionMkt,
  // stakefunction,
}) {
  const { id, mktId } = useParams();

  const dispatch = useDispatch();
  const [book1Stack, setbook1Stack] = useState("0");
  const handleClick = (betDetails, bettype, marketType, value) => {
    // console.log("click", value);
    handleModal(true);
    setRealtimeData(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    // setsubBetDetails(betDetails);
    setDataValue(value);
  };
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);

  const { bookStack, loading } = useSelector((state) => state.betStack);

  useEffect(() => {
    if (!loading) {
      if (tabName?.runnerName == bookStack[0]?.team) {
        setbook1Stack(bookStack[0]?.amount);
      } else if (tabName?.runnerName == bookStack[1]?.team) {
        setbook1Stack(bookStack[1]?.amount);
      } else if (tabName?.runnerName == bookStack[2]?.team) {
        setbook1Stack(bookStack[2]?.amount);
      }
    }
  }, [dispatch, loading]);



  return (
    <div className="row">
      <div className="col-7 betting-t-n ">
        <p className="ms-2 mb-0 d-flex ">{tabName?.runnerName}</p>
        <p className={`ms-3  ${book1Stack >= 0 ? "success-1" : "zero"}`}>
          {book1Stack}
        </p>
      </div>
      <div className="col-5 d-flex pos_re">
        {(sus.status === "SUSPENDED" || sus.status === "Ball Running") && (
          <div className="tr_data_1">
            <button className="spnd">{sus.status}</button>
          </div>
        )}
        <div className="blue-bet-btn mb-0">
          {isNaN(back[0]?.price) ? (
            <div className="d-flex flex-column align-items-center">
              0.00
              <span>0.00</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center"
              onClick={() =>
                handleClick(
                  {
                    marketId: sessionMkt, //mktId
                    evntid: eventTypeid,
                    matchid: id,
                    selectionId: sus.selectionId,
                    runnerName: tabName.runnerName,
                    handicap: 0,
                    status: sus.status,
                    lastPriceTraded: 0,
                    totalMatched: 0,
                    Backprice1: back[0]?.price * 100 - 100,
                    Backprice: back[0]?.price * 100 - 100,
                    Backsize: back[0].size,
                    Layprice1: 0,
                    Layprice: 0,
                    Laysize: 0,
                    min: 0,
                    max: 0,
                    mname: "Bookmaker Market",
                    betRunnerName: tabName.runnerName,
                  },
                  "back",
                  "Bookmaker Market",
                  { valueOf: back[0]?.price, selId: sus.selectionId }
                )
              }
            >
              {parseFloat(back[0]?.price * 100 - 100).toFixed(2)}
              <span>
                {back[0]?.size < 1000
                  ? back[0]?.size
                  : (back[0]?.size / 1000).toFixed(1) + "K"}
              </span>
            </div>
          )}
        </div>
        <div className=" orange-bet-btn ms-1 mb-0">
          {isNaN(lay[0]?.price) ? (
            <div className="d-flex flex-column align-items-center">
              0.00
              <span>0.00</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center"
              onClick={() =>
                handleClick(
                  {
                    marketId: sessionMkt, //mktId
                    evntid: eventTypeid,
                    matchid: id,
                    selectionId: sus.selectionId,
                    runnerName: tabName.runnerName,
                    handicap: 0,
                    status: sus.status,
                    lastPriceTraded: 0,
                    totalMatched: 0,
                    Backprice1: 0,
                    Backprice: 0,
                    Backsize: 0,
                    Layprice1: lay[0]?.price * 100 - 100,
                    Layprice: lay[0]?.price * 100 - 100,
                    Laysize: lay[0].size,
                    min: 0,
                    max: 0,
                    mname: "Bookmaker Market",
                    betRunnerName: runnerName,
                  },
                  "lay",
                  "Bookmaker Market",
                  { valueOf: lay[0]?.price, selId: sus.selectionId }
                )
              }
            >
              {parseFloat(lay[0]?.price * 100 - 100).toFixed(2)}
              <span>
                {" "}
                {lay[0]?.size < 1000
                  ? lay[0]?.size
                  : (lay[0]?.size / 1000).toFixed(1) + "K"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(BookTable);
