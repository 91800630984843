import React, { useState, useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import SliderH from "../MatchesDetail/SliderH";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import aircraft from "../../Media/output-onlinepngtools (5).png";
import { NavLink } from "react-router-dom";

// import "./MatchesDetail.css";
import cricket from "../../Media/5.png";

import tennis from "../../Media/4.png";
import football from "../../Media/6.png";
import InPlayMatchData from "../MatchesDetail/InPlayMatchData";
import { useDispatch, useSelector } from "react-redux";
import { fetchMatchName } from "../../Redux/Slices/matchNameSlice";

const InPlay = () => {
  const [isVisible, setIsVisible] = useState(true);
  const dispatch = useDispatch();

  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const [activeIndex, setActive] = useState("Cricket");
  // useEffect(() => {
  //   // Toggle the visibility of the image every second
  //   const interval = setInterval(() => {
  //     setIsVisible((prevVisible) => !prevVisible);
  //   }, 500);

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, []);

  const { matchName, loading, error } =
    useSelector((state) => state.matchInfo) || {};

  useEffect(() => {
    dispatch(fetchMatchName({ id: 0, token })).unwrap();
  }, [dispatch]);

  if (!matchName || !matchName.length) {
    return <div>Loading...</div>; // or handle error
  }

  const newArray = matchName.reduce((groups, item) => {
    const { eventTypeid } = item;
    const sportName = getSportName(eventTypeid);
    groups[sportName] = groups[sportName] || [];
    groups[sportName].push(item);
    return groups;
  }, {});

  function getSportName(key) {
    switch (key) {
      case "4":
        return "Cricket";
      case "2":
        return "Tennis";
      case "1":
        return "Football";
      default:
        return "Other";
    }
  }


  return (
    <div className="col-lg-10 home">
      <div className="row home ">
        <div className=" col-lg-8 p-2 center-div">
          <SliderH />
          <div className="MainArea ">
            <div className="sport-div bg-dark row">
              <div className="gamess">
              <div
                onClick={() => {
                  setActive("Cricket");
                }}
                className={`single-sport-div ${
                  activeIndex === "Cricket" && "is-active"
                }`}
              >
                <div className="row">
                  <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                    <div
                      className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                        activeIndex === "Cricket" && "is-activ"
                      }`}
                    >
                      <img
                        className=" sport-img ms-1"
                        src={cricket}
                        alt="cricket"
                      />
                    </div>
                    <p
                      className={`ms-1 sport-name mt-1 ${
                        activeIndex === "Cricket" && "is-act"
                      }`}
                    >
                      CRICKET
                    </p>
                  </div>
                  <div className="col-2 ">
                    <div className="num-div d-flex justify-content-center ">
                      {newArray["Cricket"]?.length||0}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="single-sport-div"
                onClick={() => {
                  setActive("Football");
                }}
              >
                <div className="row">
                  <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                    <div className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                        activeIndex === "Football" && "is-activ"
                      }`}>
                      <img
                        className=" sport-img "
                        src={football}
                        alt="cricket"
                      />
                    </div>
                    <p
                      className={`ms-1 sport-name mt-1 ${
                        activeIndex === "Football" && "is-act"
                      }`}
                    >
                      SOCCER
                    </p>
                  </div>
                  <div className="col-2">
                    <div className="num-div d-flex justify-content-center">
                      {newArray["Football"]?.length||0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-sport-div"  onClick={() => {
                  setActive("Tennis");
                }}>
                <div className="row">
                  <div className="col-10 d-flex flex-column justify-content-center align-items-center ">
                    <div className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                        activeIndex === "Tennis" && "is-activ"
                      }`}>
                      <img
                        className=" sport-img ms-1"
                        src={tennis}
                        alt="cricket"
                      />
                    </div>
                    <p className={`ms-1 sport-name mt-1 ${
                        activeIndex === "Tennis" && "is-act"
                      }`}>TENNIS</p>
                  </div>
                  <div className="col-2">
                    <div className="num-div d-flex justify-content-center align-items-center">
                      {newArray["Tennis"]?.length||0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-sport-div ms-4">
                <NavLink to="/ColorGame" className="nav-link">
                  <div className="blink-img-div">
                    {isVisible && (
                      <img
                        className="image-size img-fluid "
                        src={colorimg}
                        alt="a"
                      />
                    )}
                  </div>
                  <p className="ms-1  sport-name">COLOR</p>
                </NavLink>
              </div>
              <div className="single-sport-div ms-1">
                <NavLink to="/Aviator" className="nav-link">
                  <div className="image-div">
                    {isVisible && (
                      <img
                        className=" a-image-size img-fluid mt-2"
                        src={aircraft}
                        alt="a"
                      />
                    )}
                  </div>
                  <p className="ms-1 mt-1 sport-name mt-3">AVIATOR</p>
                </NavLink>
              </div>
              </div>
              {/* <----------------------MatchData-----------------------> */}
              {/* <MatchData/> */}
              {newArray[activeIndex]?.map((item, index) => (
                <InPlayMatchData key={index} data={item} />
              ))}

              {/* <InPlayMatchData />
              <InPlayMatchData />
              <InPlayMatchData /> */}

              {/* <MatchData/> */}
              {/* <MatchData/> */}
              {/* <MatchData/> */}
           
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  );
};

export default InPlay;
