import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserDepositHistory } from "../api/depositHistoryAPI";

const initialState = {
  depositHistory: [],
  isLoading: false,
  invalidToken: false,
  error: null,
};

export const fetchUserdepositHistoryData = createAsyncThunk(
  "depositHistory/fetchUserdepositHistoryData",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const data = await fetchUserDepositHistory(id, token);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const depositHistorySlice = createSlice({
  name: "depositHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserdepositHistoryData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserdepositHistoryData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.depositHistory = action.payload;
        state.error = null;
      })
      .addCase(fetchUserdepositHistoryData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload?.message === "Invalid authentication token") {
          state.invalidToken = true;
        }
      });
  },
});

export default depositHistorySlice.reducer;
