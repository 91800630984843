import React from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import MatchOdds from "./MatchOdds";
import SessionMarket from "./SessionMarket";
import BetHistory from "./BetHistory";

const LokSabha = () => {


  return (
    <div className="col-lg-10  pt-1  ">
     
      <div className="row px-1">
        <div className="col-lg-8  px-2 score-card">
          <h4> Lok Sabha 2024 </h4>
          {/* <BetHistory/> */}
          <MatchOdds />
          <SessionMarket/>
        </div>
        <RightSidebar />
      </div>
     
    </div>
  );
};

export default LokSabha;
