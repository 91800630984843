import React, { useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./ThirtyTwoCards.css";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useState } from "react";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RulesModal from "../Rules_Modal";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
// import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";

const ThirtyTwoCards = () => {
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betno, setBetNum] = useState(0);

  const { casinobetHistory, loading } = useSelector(
    (state) => state.casinobetHistory
  );

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  // console.log("stack_3patti", thirtyTwoCardStack);

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult teen:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://inplay-socket-api.nakshtech.info/";

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
      });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }


  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  

  

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
  };

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);
  useEffect(() => {
    ResultScheduler_API()
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) :  */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const DataModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowdataModal(false);
          }}
          className="data-modal-wrapper"
        ></div>
        <div className="data-modal-container">
          <div className="head-border d-flex align-items-center">
            <p className="my-auto mx-2">{name}</p>
          </div>
          <div className="container-body">
            <p className="my-1 mx-2">Player 9</p>
            <div className="row">
              <div className="col-4">
                <p className="mx-2 ">Odds</p>
              </div>
              <div className="col-8">
                <button className="modal-w-btn">19.5</button>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="mx-2 ">Stake</p>
              </div>
              <div className="col-8">
                <button className="modal-w-btn">2</button>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="mx-2 ">Profit</p>
              </div>
              <div className="col-8">
                <button className="modal-g-btn">19.5</button>
              </div>
            </div>
            <div className="orange-buttons-row d-flex flex-wrap">
              <button className="orb mx-1 my-1">5</button>
              <button className="orb mx-1 my-1">10</button>
              <button className="orb mx-1 my-1  ">20</button>
              <button className="orb mx-1 my-1">50</button>
              <button className="orb mx-1 my-1">100</button>
              <button className="orb mx-1 my-1">200</button>
              <button className="orb mx-1 my-1">500</button>
              <button className="orb mx-1 my-1">1000</button>
            </div>
          </div>
          <div className="modal-footer row mt-1">
            <div className="col-5"></div>
            <div className="col-7 py-2">
              <button
                onClick={() => {
                  setShowdataModal(false);
                }}
                className="mcb "
              >
                Cancel
              </button>
              <button className="mbb mx-1">Place Bet</button>
            </div>
          </div>
        </div>
      </>
    );
  };

  // console.log("casino Result", casinoGameResult)
  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player A</div>
                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index % 2 === 0 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index % 2 !== 0 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="col-lg-10 ">
      <div className="row home ">
        <div className="col-lg-8 p-1">
          <div className="teenpatti-game-div pt-2 p-2">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 ">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2">
                  Round ID: {reqRes?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>

            <div className="card  teenpatti-game-card p-1 mt-1">
              <div className="row">
                <div className="col-10">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""
                    ></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            {/* <-------------------------------Back-lay-table----------> */}
            <div>
              <div className="table-h-div row">
                <div className="col-5 d-flex align-items-center ps-2"></div>
                <div className="col-2 d-flex align-items-center justify-content-center px-2">
                  Back
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center "></div>
              </div>

              <div className="card-table-inner-row row">
                <div className="col-5 d-flex align-items-center ps-2">
                  {reqRes?.t2[0]?.nat}{" "}
                  {/* <span className="success-o ms-1"> 0</span> */}
                </div>

                <div className="col-2 d-flex align-items-center justify-content-center px-2 position-relative">
                  {reqRes?.t2[0]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}

                  <button
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(1);
                      handleModal(reqRes?.t2[0]?.rate, reqRes?.t2[0]?.nat, 1);
                    }}
                    className="back-btn"
                  >
                    {reqRes?.t2[0]?.rate}
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele, index) =>
                          ele.number === 1 && (
                            <span
                              className={`  ${
                                ele.number == 1 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}
                              key={index}
                            >
                              {ele.number == 1 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                  </button>
                  {/* )} */}
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center position-relative">
                  {reqRes?.t2[0]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}
                  <button
                    className="back_plus"
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(2);
                      handleModal(reqRes?.t2[1]?.rate, reqRes?.t2[1]?.nat, 2);
                    }}
                  >
                    {reqRes?.t2[1]?.nat} ({reqRes?.t2[1]?.rate})
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 2 && (
                            <span
                              className={`  ${
                                ele.number == 2 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}
                            >
                              {ele.number == 2 && ele.amount}
                            </span>
                          )
                      )}{" "}
                    </p>
                  </button>
                </div>
              </div>
              <div className="card-table-inner-row row">
                <div className="col-5 d-flex align-items-center ps-2">
                  {reqRes?.t2[2]?.nat}{" "}
                  {/* <span className="success-o ms-1"> 0</span> */}
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center px-2 position-relative">
                  {reqRes?.t2[2]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}
                  <button
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(3);
                      handleModal(reqRes?.t2[2]?.rate, reqRes?.t2[2]?.nat, 3);
                    }}
                    className="back-btn"
                  >
                    {reqRes?.t2[2]?.rate}
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 3 && (
                            <span
                              className={` ${
                                ele.number == 3 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}
                            >
                              {ele.number == 3 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                  </button>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center position-relative">
                  {reqRes?.t2[2]?.gstatus == 0 && (
                    <div className="suspended__1">
                      <i className="fa fa-lock"></i>
                    </div>
                  )}
                  <button
                    className="back_plus"
                    onClick={() => {
                      // setShowModal(true);
                      // setBetNum(4);
                      handleModal(reqRes?.t2[3]?.rate, reqRes?.t2[3]?.nat, 4);
                    }}
                  >
                    {reqRes?.t2[3]?.nat} ({reqRes?.t2[3]?.rate})
                    <p>
                      {thirtyTwoCardStack.map(
                        (ele) =>
                          ele.number === 4 && (
                            <span
                              className={` ${
                                ele.number == 4 && ele.amount >= 0
                                  ? "success-1"
                                  : "zero"
                              }`}
                            >
                              {ele.number == 4 && ele.amount}
                            </span>
                          )
                      )}
                    </p>
                  </button>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

            <div className="Results-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Last 10 Results</p>
                </div>
              </div>
              <div className="card rst-card py-1 d-flex">
                <div className="rst-row d-flex">
                  {results?.map((result, index) => {
                    const item = reqRes.t2.find(
                      (item) => item.sid === result?.result
                    );

                    return (
                      <div
                        onClick={() => {
                          setMidRound(result.mid);
                          CasinoGameResult_API(result.mid);
                          setShowresultModal(true);
                        }}
                        className="rst-btn ms-1"
                        key={index}
                      >
                        {item && <>{item.nat[item.nat.length - 1]}</>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              {HistoryTable}
            </div>
          </div>
        </div>

        <RightSidebar />
        {showresultModal && <ResultsModal />}
        {/* {showDataModal && <DataModal />} */}
        {showModal && (
          <Modal
            id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
          />
        )}
        {rulesModal && <RulesModal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
};

export default ThirtyTwoCards;
