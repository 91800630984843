import React from "react";
import { useParams } from "react-router-dom";

export default function AviatorIframe() {
  const { Url } = useParams();

  // const oldiframeUrl = `https://stream.1ex99.in/tv2?EventId=${Url}`;
  // const iframeUrl1 = `https://tele.bazigarprediction.website/tv-cricket/tv.php?match_id=${Url}`;
  const iframeUrl2 = `https://param.apigang.com/api/v3/livetv2.php?eventId=${Url}`;

  return (
    <>
      <div className="" style={{ height: "100vh" }}>
        <iframe height="100%" width="100%" src={iframeUrl2} title="" allowFullScreen></iframe>
      </div>
    </>
  );
}
