import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import OddsTable from "./Table/OddsTable";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useDispatch, useSelector } from "react-redux";
import CashOut from "./CashOut";
import { toast } from "react-toastify";

const MatchOdds = ({
  counteyName,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  realtimeData,
  betType,
  marketType,
  // cashOut
}) => {
  // console.log("mkid",counteyName);
  const { mktId, id } = useParams();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("userData");
  // console.log("id",userId)
  const token = sessionStorage.getItem("token");
  const SOCKET_URL = "https://inplay-socket-api.nakshtech.info/";
  const [t1, setT1] = useState([]);
  const [t2, setT2] = useState([]);
  const [t3, setT3] = useState([]);
  const [t4, setT4] = useState([]);
  const [showMatchOdds, setshowMatchOdds] = useState(false);
  const [drawt1, setdrawT1] = useState([]);
  const [drawt2, setdrawT2] = useState([]);
  const [sus, setSus] = useState("0.0");
  const [sus1, setSus1] = useState("0.0");
  const [sus2, setSus2] = useState("0.0");
  const [dataValue, setDataValue] = useState("");
  const [DataForCash, setDataForCash] = useState([]);
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);

  const { lastbet } = useSelector((state) => state.lastbetDetails);
  const { oddStack } = useSelector((state) => state.betStack);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatchType", mktId, eventTypeid);
    cricketMatchesSocket.on("CricketOddMatchType_FromAPI", (data) => {
      // console.log("MatchOdds==>", data);

      if (Array.isArray(data) && data.length > 0 && data[0] !== null) {
        let res = JSON.parse(data);
        setshowMatchOdds(true);
        setT1(res.runners[0]?.ex?.availableToBack || []);
        setT2(res.runners[0]?.ex?.availableToLay || []);
        setT3(res.runners[1]?.ex?.availableToBack || []);
        setT4(res.runners[1]?.ex?.availableToLay || []);
        setdrawT1(res.runners[2]?.ex?.availableToBack || []);
        setdrawT2(res.runners[2]?.ex?.availableToLay || []);
        setSus(res.runners[0] || {});
        setSus1(res.runners[1] || {});
        setSus2(res.runners[2] || {});
        setDataForCash(res.runners);
      } else {
        setshowMatchOdds(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [mktId]);

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId,
        mId: id,
        matchType: "Match Odds",
        token,
      })
    );
  }, [dispatch, id]);

  const checkValid = () => {
    if (
      realtimeData &&
      marketType === "Match Odds" &&
      (sus?.selectionId !== dataValue?.selId ||
        sus1?.selectionId !== dataValue?.selId ||
        sus2?.selectionId !== dataValue?.selId)
    ) {
      console.log("valid1", dataValue, realtimeData);
      if (betType === "back") {
        if (
          t1[0]?.price == dataValue?.valueOf ||
          t3[0]?.price == dataValue?.valueOf ||
          drawt1[0]?.price == dataValue?.valueOf
        ) {
          return;
        } else {
          console.log("valid2", t1[0]?.price);
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          t2[0]?.price !== dataValue?.valueOf ||
          t4[0]?.price !== dataValue?.valueOf ||
          drawt2[0]?.price !== dataValue?.valueOf
        ) {
          return;
        } else {
          console.log("valid3", t2[0]?.price);
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, t1]);

  const [showCashout, setShowCashOut] = useState(false);
  const [cashOut, setCashout] = useState(0);
  const [odd1Stack, setOdd1Stack] = useState(0);
  const [odd2Stack, setOdd2Stack] = useState(0);
  const [odd3Stack, setOdd3Stack] = useState(0);

  const calculateLiability = () => {
    let liability =
      odd1Stack > 0 && odd2Stack > 0
        ? "0"
        : Math.abs(Math.min(odd1Stack, odd2Stack));
    return liability;
  };

  const calculatePoints = () => {
    if (counteyName && counteyName.length > 0) {
      if (lastbet[0]?.runnerName === counteyName[0]?.runnerName) {
        return lastbet[0]?.Bet === "Back" ? t2[0]?.price : t1[0]?.price;
      } else if (lastbet[0]?.runnerName === counteyName[1]?.runnerName) {
        return lastbet[0]?.Bet === "Back" ? t4[0]?.price : t3[0]?.price;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const calculatedifference = (points) => {
    let difference = Math.abs(points - lastbet[0]?.Bhav);
    difference =
      lastbet[0]?.Bet === "Back" && points > lastbet[0]?.Bhav
        ? difference * -1
        : (difference =
            lastbet[0]?.Bet === "Lay" && lastbet[0]?.Bhav > points
              ? difference * -1
              : difference);
    return difference;
  };

  const calculateCashOut = (diff, lia) => {
    // console.log("123",diff,lia,lastbet[0].Stakebet)
    if (lia == 0) {
      return Math.max(odd1Stack, odd2Stack);
    } else {
      return diff * lastbet[0].Stakebet;
    }
  };
  const calculateDivide = (cashout) => {
    // let ModifiyCashout = cashout > 0 ? cashout / 2 : cashout;
    let ModifiyCashout = cashout;
    return ModifiyCashout;
  };
  function calcuteTenPercentage(divide) {
    const TenPercent =
      divide < 0
        ? divide - Math.abs(divide * 0.1)
        : divide - Math.abs(divide * 0.1);
    return TenPercent;
  }
  const Cashout = useCallback(() => {
    const pointss = calculatePoints();
    // console.log("points", pointss);
    const difference = calculatedifference(pointss);
    // const profit = calculateProfit();
    const Liability = calculateLiability();
    const cashOut =
      (odd1Stack > 0 && odd2Stack > 0) || (odd1Stack < 0 && odd2Stack < 0)
        ? (odd1Stack + odd2Stack) / 2
        : calculateCashOut(difference, Liability);
    // console.log("Last", cashOut);
    // ADD Commission
    const divide = calculateDivide(cashOut);
    const AddTenPercentage = calcuteTenPercentage(divide);
    const finalcashout =
      AddTenPercentage < 0 && Math.abs(AddTenPercentage) > Liability
        ? -1 * Liability
        : AddTenPercentage;
    // console.log("final", finalcashout);
    return finalcashout;
  }, [odd1Stack, odd2Stack, lastbet, t1, t2, t3, t4]);

  useEffect(() => {
    if (lastbet.length > 0) {
      setShowCashOut(true);
    }
  }, [lastbet]);
  // useEffect(() => {
  //   if (odd1Stack == 0 || odd2Stack == 0 ) {
  //     console.log("setShowCashout",odd1Stack,odd2Stack)
  //     setShowCashOut(false);
  //   }
  // }, [odd1Stack, odd2Stack]);

  ///====================+++++jainsb code impliment start ================++++++++++++

  // var {  game } = useParams();

  // const [gameEvent, setGameEvent] = useState({});
  // // const dispatch = useDispatch();
  // const gameOdds_ = useSelector((state) => state.gameData[game + "Odds"][id]);
  // // const gameOdds = useMemo(() => gameOdds_, [gameOdds_]);
  // var gameSettings_ = useSelector((state) => state.gameData[game + "Settings"]);
  // // var gameSettings = useMemo(() => gameSettings_, [gameSettings_]);
  // // if (gameSettings && gameSettings[id]) {
  // //   gameSettings = gameSettings[id];
  // // }
  // const userData_ = useSelector((state) => state.account.userData);
  // // const userData = useMemo(() => userData_, [userData_]);

  // const getSoccerOddsByEventId = useCallback(async () => {
  //   const { response } = await getSoccerOddsByMatchIdAPI(id);
  //   let response_ = {};
  //   response_[id] = response;
  //   dispatch({
  //     type: "gameData/setSoccerOdds",
  //     payload: response_,
  //   });
  // }, [id]);

  // const getCricketOddsByMatchId = useCallback(async () => {
  //   const { response } = await getCricketOddsByMatchIdAPI(id);
  //   let response_ = {};
  //   response_[id] = response;
  //   dispatch({
  //     type: "gameData/setCricketOdds",
  //     payload: response_,
  //   });
  // }, [id]);

  // const getGameEventDetails = useCallback(async () => {
  //   if (game == "soccer") {
  //     const { response } = await getSoccerEventDetailsAPI(id);
  //     setGameEvent(response);
  //   } else if (game == "tennis") {
  //     const { response } = await getTennisEventDetailsAPI(id);
  //     setGameEvent(response);
  //   } else if (game == "cricket") {
  //     const { response } = await getCricketEventDetailsAPI(id);
  //     setGameEvent(response);
  //   }
  // }, [id, game]);

  // const getTennisOddsByMatchId = useCallback(async () => {
  //   const { response } = await getTennisOddsByMatchIdAPI(id);
  //   let response_ = {};
  //   response_[id] = response;
  //   dispatch({
  //     type: "gameData/setTennisOdds",
  //     payload: response_,
  //   });
  // }, [id]);

  // useEffect(() => {
  //   dispatch({
  //     type: "accountData/setLoading",
  //     payload: true,
  //   });
  //   if (game === "soccer") {
  //     getSoccerOddsByEventId();
  //   } else if (game === "tennis") {
  //     getTennisOddsByMatchId();
  //   } else {
  //     getCricketOddsByMatchId();
  //   }
  //   getGameEventDetails();
  //   let pollingInterval = null;
  //   pollingInterval = setInterval(() => {
  //     if (game === "soccer") {
  //       getSoccerOddsByEventId();
  //     } else if (game === "tennis") {
  //       getTennisOddsByMatchId();
  //     } else {
  //       getCricketOddsByMatchId();
  //     }
  //   }, 1000);
  //   const interval = setInterval(() => {
  //     dispatch({
  //       type: "accountData/setLoading",
  //       payload: false,
  //     });
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //     clearInterval(pollingInterval);
  //   };
  // }, [game, id]);

  // useEffect(() => {
  //   dispatch({
  //     type: "gameData/setSelectedEventForBet",
  //     payload: null,
  //   });
  // }, [game, id, dispatch]);

  // const setSelectedEventForBet = (data, isCashout = false) => {
  //   if (data.isBookmaker) {
  //     dispatch({
  //       type: "gameData/setSelectedEventForBet",
  //       payload: {
  //         ...data,
  //         eventId: id,
  //         amount: isCashout ? data.amount : 0,
  //         profit: getProfit(0, game, data).profit,
  //         loss: getProfit(0, game, data).loss,
  //       },
  //     });
  //   } else {
  //     dispatch({
  //       type: "gameData/setSelectedEventForBet",
  //       payload: {
  //         ...data,
  //         eventId: id,
  //         amount: isCashout ? data.amount : 0,
  //         profit: getProfit(0, game, data).profit,
  //         loss: getProfit(0, game, data).loss,
  //       },
  //     });
  //   }
  // };

  // const getMatchDetailsSoccer = useCallback(async () => {
  //   const { response } = await getMatchDetailsSoccerAPI(id);
  //   dispatch({
  //     type: "gameData/setMatchSettings",
  //     payload: {
  //       game,
  //       settings: response,
  //       eventId: id,
  //     },
  //   });
  // }, [dispatch, game, id]);

  // const getMatchDetailsTennis = useCallback(async () => {
  //   const { response } = await getMatchDetailsTennisAPI(id);
  //   dispatch({
  //     type: "gameData/setMatchSettings",
  //     payload: {
  //       game,
  //       settings: response,
  //       eventId: id,
  //     },
  //   });
  // }, [dispatch, game, id]);

  // const getCricketMatchDetails = useCallback(async () => {
  //   const { response } = await getMatchDetailsCricketAPI(id);

  //   dispatch({
  //     type: "gameData/setMatchSettings",
  //     payload: {
  //       game,
  //       settings: response,
  //       eventId: id,
  //     },
  //   });
  // }, [dispatch, game, id]);

  const handleCashoutClick = (
    marketIdExternal,
    marketType = "matchOdds",
    sports = "cricket"
  ) => {
    const plExposures = ["77", "-100"];

    // for (let i = 0; i < gameSettings?.plExposureResponseDTOs.length; i++) {
    //   if (
    //     gameSettings?.plExposureResponseDTOs[i].marketIdExternal ===
    //     marketIdExternal
    //   ) {
    //     for (
    //       let j = 0;
    //       j <
    //       gameSettings?.plExposureResponseDTOs[i].runnerPlExposureDTOS.length;
    //       j++
    //     ) {
    //       plExposures.push(
    //         gameSettings?.plExposureResponseDTOs[i].runnerPlExposureDTOS[j]
    //       );
    //     }
    //   }
    // }

    plExposures.sort((a, b) => b.exposure - a.exposure);

    var odds = ["1.98", "1.99"];
    // if (sports == "cricket") {
    //   for (let i = 0; i < gameOdds[marketType][0].oddDetailsDTOS.length; i++) {
    //     if (
    //       plExposures.find(
    //         (item) =>
    //           item.selectionId ==
    //           gameOdds[marketType][0].oddDetailsDTOS[i].selectionId
    //       )
    //     ) {
    //       odds.push({
    //         ...gameOdds[marketType][0].oddDetailsDTOS[i],
    //         exposure: plExposures.find(
    //           (item) =>
    //             item.selectionId ==
    //             gameOdds[marketType][0].oddDetailsDTOS[i].selectionId
    //         ).exposure,
    //       });
    //     }
    //   }
    // } else {
    //   for (
    //     let i = 0;
    //     i < gameOdds?.matchOddsResponseDTO[0].oddDetailsDTOS.length;
    //     i++
    //   ) {
    //     if (
    //       plExposures.find(
    //         (item) =>
    //           item.selectionId ==
    //           gameOdds?.matchOddsResponseDTO[0].oddDetailsDTOS[i].selectionId
    //       )
    //     ) {
    //       odds.push({
    //         ...gameOdds?.matchOddsResponseDTO[0].oddDetailsDTOS[i],
    //         exposure: plExposures.find(
    //           (item) =>
    //             item.selectionId ==
    //             gameOdds?.matchOddsResponseDTO[0].oddDetailsDTOS[i].selectionId
    //         ).exposure,
    //       });
    //     }
    //   }
    // }
    odds.sort((a, b) => b.exposure - a.exposure);
    // var oppositeTeam = {
    //   back1: parseFloat(odds[1]?.back1),
    //   back2: parseFloat(odds[1]?.back2),
    //   back3: parseFloat(odds[1]?.back3),
    //   backSize1: parseFloat(odds[1]?.backSize1),
    //   backSize2: parseFloat(odds[1]?.backSize2),
    //   backSize3: parseFloat(odds[1]?.backSize3),
    //   lay1: parseFloat(odds[1]?.lay1),
    //   lay2: parseFloat(odds[1]?.lay2),
    //   lay3: parseFloat(odds[1]?.lay3),
    //   laySize1: parseFloat(odds[1]?.laySize1),
    //   laySize2: parseFloat(odds[1]?.laySize2),
    //   laySize3: parseFloat(odds[1]?.laySize3),
    //   exposure: odds[1]?.exposure,
    //   runnerName: odds[1]?.runnerName,
    // };

    var oppositeTeam = {
      back1: parseFloat("2.26"),
      back2: parseFloat("2.25"),
      back3: parseFloat("2.24"),
      backSize1: parseFloat("2500"),
      backSize2: parseFloat("2900"),
      backSize3: parseFloat("3500"),
      lay1: parseFloat("2.3"),
      lay2: parseFloat("2.31"),
      lay3: parseFloat("2.32"),
      laySize1: parseFloat("3500"),
      laySize2: parseFloat("4000"),
      laySize3: parseFloat("3000"),
      exposure: "-100",
      runnerName: "Rajasthan Royals",
    };

    var sameTeam = {
      back1: parseFloat("1.98"),
      back2: parseFloat("1.97"),
      back3: parseFloat("1.96"),
      backSize1: parseFloat("2500"),
      backSize2: parseFloat("2900"),
      backSize3: parseFloat("3500"),
      lay1: parseFloat("1.99"),
      lay2: parseFloat("2.00"),
      lay3: parseFloat("2.01"),
      laySize1: parseFloat("3500"),
      laySize2: parseFloat("4000"),
      laySize3: parseFloat("3000"),
      exposure: "77",
      runnerName: "Gujarat Titans",
    };

    console.log("Gujarat Titans");
    // var sameTeam = {
    //   back1: parseFloat(odds[0]?.back1),
    //   back2: parseFloat(odds[0]?.back2),
    //   back3: parseFloat(odds[0]?.back3),
    //   backSize1: parseFloat(odds[0]?.backSize1),
    //   backSize2: parseFloat(odds[0]?.backSize2),
    //   backSize3: parseFloat(odds[0]?.backSize3),
    //   lay1: parseFloat(odds[0]?.lay1),
    //   lay2: parseFloat(odds[0]?.lay2),
    //   lay3: parseFloat(odds[0]?.lay3),
    //   laySize1: parseFloat(odds[0]?.laySize1),
    //   laySize2: parseFloat(odds[0]?.laySize2),
    //   laySize3: parseFloat(odds[0]?.laySize3),
    //   exposure: odds[0]?.exposure,
    //   runnerName: odds[0]?.runnerName,
    // };
    var calculationMap = {
      pandl: -Infinity,
      amount: 0,
      oddDecided: 0,
      type: "back",
      oddRate: 0,
    };
    function calculatePandL(oddType, OddSize) {
      if (oddType == "back1" || oddType == "back2" || oddType == "back3") {
        var pandlInternal =
          (oppositeTeam.exposure +
            oppositeTeam[oddType] * sameTeam.exposure -
            sameTeam.exposure) /
          oppositeTeam.oddType;
        var amountInternal = sameTeam.exposure - pandlInternal;
        if (calculationMap.pandl < pandlInternal && amountInternal > 0) {
          calculationMap.pandl = pandlInternal;
          calculationMap.amount = amountInternal;
          calculationMap.oddDecided = oppositeTeam[oddType];
          calculationMap.type = "back";
          calculationMap.oddRate = oppositeTeam[OddSize];
          calculationMap.runnerName = sameTeam.runnerName;
        }
      } else {
        var pandlInternal =
          (sameTeam.exposure +
            sameTeam[oddType] * oppositeTeam.exposure -
            oppositeTeam.exposure) /
          sameTeam[oddType];
        var amountInternal = pandlInternal - oppositeTeam.exposure;
        if (calculationMap.pandl < pandlInternal && amountInternal > 0) {
          calculationMap.pandl = pandlInternal;
          calculationMap.amount = amountInternal;
          calculationMap.oddDecided = sameTeam[oddType];
          calculationMap.type = "lay";
          calculationMap.oddRate = sameTeam[OddSize];
          calculationMap.runnerName = sameTeam.runnerName;
        }
      }
    }
    calculatePandL("back1", "backSize1");
    calculatePandL("back2", "backSize2");
    calculatePandL("back3", "backSize3");
    calculatePandL("lay1", "laySize1");
    calculatePandL("lay2", "laySize2");
    calculatePandL("lay3", "laySize3");
    console.log("pcashamount", parseFloat(calculationMap.amount).toFixed(2));
    if (calculationMap.pandl == -Infinity) {
      toast({
        title: "You are not eligible for cashout",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // setSelectedEventForBet(
    //   {
    //     selectedOdd: calculationMap.oddDecided,
    //     selectedRate: calculationMap.oddRate,
    //     amount: parseFloat(calculationMap.amount).toFixed(2),
    //     isBookmaker: false,
    //     eventId: id,
    //     marketId: marketIdExternal,
    //     runnerName: calculationMap.runnerName,
    //     selectedId: odds[0]?.selectionId,
    //     marketName:
    //       game == "cricket"
    //         ? gameOdds?.matchOdds[0]?.marketName
    //         : gameOdds?.matchOddsResponseDTO[0]?.marketName,
    //     marketType,
    //     sports,
    //     isCashout: true,
    //     type: calculationMap.type,
    //   },
    //   true
    // );
  };

  // useEffect(() => {
  //   if (userData.token) {
  //     if (game == "soccer") {
  //       getMatchDetailsSoccer();
  //     } else if (game == "tennis") {
  //       getMatchDetailsTennis();
  //     } else if (game == "cricket") {
  //       getCricketMatchDetails();
  //     }
  //     let interval = setInterval(() => {
  //       if (game == "soccer") {
  //         getMatchDetailsSoccer();
  //       } else if (game == "tennis") {
  //         getMatchDetailsTennis();
  //       } else if (game == "cricket") {
  //         getCricketMatchDetails();
  //       }
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }
  // }, [
  //   game,
  //   getCricketMatchDetails,
  //   getMatchDetailsSoccer,
  //   getMatchDetailsTennis,
  //   userData.token,
  // ]);

  ///====================+++++jainsb code impliment end ================++++++++++++

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit(
  //     "GetCashoutSocket",
  //     userId,
  //     mktId,
  //     t1[0]?.price,
  //     t2[0]?.price,
  //     t3[0]?.price,
  //     t4[0]?.price
  //   );
  //   cricketMatchesSocket.on("GetCashoutSocket_FromAPI", (data) => {
  //     // console.log("cashout==>", data);
  //     setCashout(data);
  //   });
  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, [userId, mktId, t1[0]?.price, t2[0]?.price, t3[0]?.price, t4[0]?.price]);
  return (
    <div>
      {/* {showCashout &&
        odd1Stack != 0 &&
        odd2Stack != 0 &&
        t1[0]?.price > 1.02 &&
        t1[1]?.price > 1.02 &&
        t2[0]?.price > 1.02 &&
        t2[1]?.price > 1.02 &&
        showMatchOdds &&
        counteyName &&
        counteyName?.length > 0 &&
        !counteyName[2]?.runnerName && (
          <div className="liab_bars">
            <CashOut
              calculateLiability={calculateLiability}
              Cashout={cashOut}
              setShowCashOut={setShowCashOut}
            />
          </div>
        )} */}
      <div className="row  bg-dark ">
        <div className="Match-ods-h d-flex align-items-center">
          <p className="mx-2 mt-3 d-flex">
            MATCH ODDS <div className="green-live-dot ms-2 mt-2"></div>
          </p>
        </div>
        <div className="row back-lay-row  bg-dark ">
          <div className="col-7 "></div>
          <div className="col-5 d-flex">
            <button className="back ">Back</button>
            <button className="lay ms-1">Lay</button>
          </div>
        </div>
        <div className="betting-team-info">
          {counteyName && counteyName.length > 0 && showMatchOdds && (
            <>
              <OddsTable
                tabName={counteyName[0]}
                runnerName={counteyName[1].runnerName}
                back={t1}
                lay={t2}
                sus={sus}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                realtimeData={realtimeData}
                setDataValue={setDataValue}
                stakefunction={setOdd1Stack}
              />
              <OddsTable
                tabName={counteyName[1]}
                runnerName={counteyName[0].runnerName}
                back={t3}
                lay={t4}
                sus={sus1}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                realtimeData={realtimeData}
                setDataValue={setDataValue}
                stakefunction={setOdd2Stack}
              />
              {counteyName.length > 2 && (
                <>
                  <OddsTable
                    tabName={counteyName[2]}
                    runnerName=""
                    back={drawt1}
                    lay={drawt2}
                    sus={sus2}
                    handleModal={handleModal}
                    setmarketType={setmarketType}
                    setbetType={setbetType}
                    setbetDetails={setbetDetails}
                    setRealtimeData={setRealtimeData}
                    setDataValue={setDataValue}
                    stakefunction={setOdd3Stack}
                  />
                </>
              )}
            </>
          )}
          {!showMatchOdds && <div className="text-center py-2">No Bet !</div>}
        </div>
      </div>
    </div>
  );
};

export default MatchOdds;
