// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../api/authAPI";

const initialState = {
  isAuth: false,
  user: null,
  error: null,
  isLoading: false,
  currentMatch:{},
};

// Define an asynchronous thunk for login
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ username, password, ipaddress }) => {
    try {
      const response = await login({ username, password, ipaddress });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: () => {
      console.log("logoutUser")
      return initialState;
    },
    updateCurrentMatch(state, action) {
      // console.log("authSlice", state, action);
      state.currentMatch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // console.log("uidlogin", action);
        state.isLoading = false;
        state.isAuth = true;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { logoutUser, updateCurrentMatch } = authSlice.actions;

export default authSlice.reducer;
