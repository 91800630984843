import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Component = () => {
    const { id } = useParams();
    const [iframeContent, setIframeContent] = useState('');

    useEffect(() => {
        const iframe = `<iframe src="https://stream.1ex99.in/tv2?EventId=${id}" width="540" height="450"></iframe>`;
        setIframeContent(iframe);
    }, [id]);

    const createMarkup = () => {
        return { __html: iframeContent };
    };

    return (
        <div>
            <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
    );
};

export default Component;
