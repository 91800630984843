import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";

export default function AviatorBetHistory() {
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const AvbetHistory_API = async () => {
    try {
      let res = await API.post(
        `/AviatorBetHistory`,
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      console.log("res", res?.data?.data[0]);
      const data = res?.data?.data[0];
      let arr = [];
      data.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          peroid: item.peroid,
          Amount: item.Amount,
          Winamount: item.Winamount,
          status: item.Betstatus,
          Date: item.edate,
        });
      });
      setreferralApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    AvbetHistory_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Peroid", accessor: "peroid" },
      { Header: "Bet Amount", accessor: "Amount" },
      { Header: "Win Amount", accessor: "Winamount" },
      { Header: "Status", accessor: "status" },
      { Header: "Date", accessor: "Date" },
    ],
  });

  return (
    <>
      <div className="col-lg-10 com-h">
        <div className="row">
          <div className="col-12 p-3">
            <div className="bet-h ">
              <h4 className="p-2 ms-2">Aviator Bet History</h4>
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
