import { createSlice } from "@reduxjs/toolkit";
import { fetchbetStackData } from "../api/betStackAPI";

const initialState = {
  oddStack: [],
  bookStack: [],
  TiedStack: [],
  loading: false,
  error: null,
};

const betStackSlice = createSlice({
  name: "betStack",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchbetStackData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchbetStackData.fulfilled, (state, action) => {
        state.loading = false;
        const { matchType } = action.meta.arg;
        if (matchType === "Match Odds") {
          state.oddStack = action.payload;
        }
        if (matchType === "Bookmaker Market") {
          state.bookStack = action.payload;
        }
        if (matchType === "Tied") {
          state.TiedStack = action.payload;
        }
      })
      .addCase(fetchbetStackData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default betStackSlice.reducer;
