import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUnsellectedBet } from "../api/UnsellectedAPI";

const initialState = {
  unsellectedBet: [],
  isLoading: false,
  invalidToken: false,
  error: null,
};

export const fetchUnsellectedBetData = createAsyncThunk(
  "unsellectedBet/fetchUnsellectedBetData",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await fetchUnsellectedBet(data, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const unsellectedBetSlice = createSlice({
  name: "unsellectedBet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnsellectedBetData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUnsellectedBetData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.unsellectedBet = action.payload;
        state.error = null;
      })
      .addCase(fetchUnsellectedBetData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload?.message === "Invalid authentication token") {
          state.invalidToken = true;
        }
      });
  },
});

export default unsellectedBetSlice.reducer;
