import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://inplay-api.nakshtech.info/",
});

export const competionwisematchDetails = createAsyncThunk(
  "match/competitionwise",
  async ({ competitionId, eventId }, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `getMatchCompititionWise?CompetitionID=${competitionId}&eventTypeid=${eventId}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const competitionwiseSlice = createSlice({
  name: "competitionwise",
  initialState: {
    error: null,
    matchs: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(competionwisematchDetails.fulfilled, (state, action) => {
        state.error = null;
        state.matchs = action.payload;
      })
      .addCase(competionwisematchDetails.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Unknown error";
      });
  },
});

export default competitionwiseSlice.reducer;
