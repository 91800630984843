import React from "react";
import SessionTable from "./Table/SessionTable";

const SessionMarket = () => {
  
    function openCity(evt, cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    }
  
  return (
    <>
      <div className="row">
        <div className="col-md-12 sec__bg">
          <div className="Match-ods-h d-flex align-items-center">
            <p className="mx-2 mt-3 d-flex">
              Session MARKET <div className="green-live-dot ms-2 mt-2"></div>
            </p>
          </div>
          <div className="row back-lay-row_1  bg-dark ">
            <div className="col-7 "></div>
            <div className="col-5 d-flex">
              <button className="lay ">No</button>
              <button className="back ms-1">Yes</button>
            </div>
          </div>

          <SessionTable />
        </div>
      </div>
      {/* <div className="row">
        <div className="">
  
          <div className="tab">
            <button className="tablinks" onclick="openCity(event, 'London')">
              London
            </button>
            <button className="tablinks" onclick="openCity(event, 'Paris')">
              Paris
            </button>
            <button className="tablinks" onclick="openCity(event, 'Tokyo')">
              Tokyo
            </button>
          </div>
          <div id="London" className="tabcontent">
            <h3>London</h3>
            <p>London is the capital city of England.</p>
          </div>
          <div id="Paris" className="tabcontent">
            <h3>Paris</h3>
            <p>Paris is the capital of France.</p>
          </div>
          <div id="Tokyo" className="tabcontent">
            <h3>Tokyo</h3>
            <p>Tokyo is the capital of Japan.</p>
          </div>
        


        </div>
      </div> */}
    </>
  );
};

export default SessionMarket;
