import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginWithWallet } from "../api/walletLoginAPI";

const initialState = {
  // isAuth: false,
  user: null,
  error: null,
  isLoading: false,
};

export const loginUserWithWallet = createAsyncThunk(
  "walletLogin/loginUserWithWallet",
  async (address, { rejectWithValue }) => {
    try {
      const data = await loginWithWallet(address);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const walletLoginSlice = createSlice({
  name: "walletLogin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUserWithWallet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUserWithWallet.fulfilled, (state, action) => {
        console.log("walletlog", action);
        state.isLoading = false;
        // state.isAuth = true;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUserWithWallet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// export const { setAuth } = walletLoginSlice.actions;
export default walletLoginSlice.reducer;
