import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "../Redux/Slices/authSlice";

const InternetStatusChecker = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.dismiss();
    };
    const handleOffline = () => {
      setIsOnline(false);
      toast.error("You are offline");
      dispatch(logoutUser())
      sessionStorage.clear();
      navigate("/");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigate]);

  return null;
};

export default InternetStatusChecker;
