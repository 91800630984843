import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import SessionTable from "./Table/SessionTable";
import { fetchSessionStackData } from "../../Redux/api/sessionStackAPI";
import { useDispatch, useSelector } from "react-redux";

const Session = ({
  setbetDetails,
  setmarketType,
  setbetType,
  handleModal,
  setcountryName,
  setrealtimeData,
  marketType,
  realtimeData,
  betType,
}) => {
  const { id } = useParams();
  const SOCKET_URL = "https://inplay-socket-api.nakshtech.info/";

  const [data, setData] = useState([]);
  const [dataValue, setDataValue] = useState("");

  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);

  const dispatch = useDispatch();

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketAllMarket1", id, eventTypeid);
    cricketMatchesSocket.on("CricketAllMarket1_FromAPI", (data) => {
      // console.log("Received data:", data);

      const parsedData = data.map((item) => {
        try {
          return JSON.parse(item);
        } catch (error) {
          // console.error("Error parsing JSON:", error);
          return null;
        }
      });
      // console.log("Parsed data:", parsedData);
      setData(parsedData);
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id, eventTypeid]);

  const checkValid = () => {
    // console.log("valid", dataValue);
    if (realtimeData && marketType === "Session Market") {
      const filteredData = data?.filter(
        (item) =>
          item.RunnerName == dataValue?.team &&
          item.SelectionId == dataValue?.sid
      );

      // console.log("filter", filteredData);
      if (betType === "back") {
        if (
          filteredData[0].SelectionId == dataValue?.sid &&
          filteredData[0].GameStatus != "SUSPENDED" &&
          filteredData[0]?.BackPrice1 == dataValue?.valueOf &&
          filteredData[0]?.RunnerName == dataValue?.team
        ) {
          return;
        } else {
          // console.log("valid2", bm[1].b1);
          setrealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].SelectionId == dataValue?.sid &&
          filteredData[0].GameStatus != "SUSPENDED" &&
          filteredData[0]?.LayPrice1 == dataValue?.valueOf &&
          filteredData[0]?.RunnerName == dataValue?.team
        ) {
          return;
        } else {
          setrealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, data]);

  useEffect(() => {
    dispatch(
      fetchSessionStackData({
        userId: uid,
        id: id,
        mname: "Session Market",
        token,
      })
    );
  }, []);
  return (
    <>
      {data && data
              .filter(
                (item) => item.gtype == "session" ).length > 0 ? (
        <div className="row">
          <div className="col-md-12 sec__bg">
            <div className="Match-ods-h d-flex align-items-center">
              <p className="mx-2 mt-3 d-flex">
                Session MARKET <div className="green-live-dot ms-2 mt-2"></div>
              </p>
            </div>
            <div className="row back-lay-row_1  bg-dark ">
              <div className="col-7 "></div>
              <div className="col-5 d-flex">
                <button className="lay ">No</button>
                <button className="back ms-1">Yes</button>
              </div>
            </div>
            {data
              .filter(
                (item) => item.gtype == "session" 
              )
              // .sort((a, b) => b.srno - a.srno)
              .map((item, index) => {
                return (
                  <SessionTable
                    key={index}
                    tabData={item}
                    handleModal={handleModal}
                    setbetType={setbetType}
                    setmarketType={setmarketType}
                    setbetDetails={setbetDetails}
                    // sessionName="Session Market"
                    setcountryName={setcountryName}
                    setrealtimeData={setrealtimeData}
                    setDataValue={setDataValue}
                  />
                );
              })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(Session);
