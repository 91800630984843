import { createSlice } from "@reduxjs/toolkit";
import { calculateProfit } from "../api/calculateProfit";

const initialState = {
  Profitdata: {},
  isLoading: false,
  error: null,
};

export const calculateUserProfit = createSlice({
  name: "userProfit",
  initialState,
  reducers: {
    resetProfitData: (state) => {
      state.isLoading = false;
      state.error = null;
      state.Profitdata = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateProfit.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(calculateProfit.rejected, (state, action) => {
        state.isLoading = true;
        state.error = action.payload;
      })
      .addCase(calculateProfit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.Profitdata = action.payload;
      });
  },
});

export const { resetProfitData } = calculateUserProfit.actions;
export default calculateUserProfit.reducer;
