import React from "react";
import "./Table_Button.css";
import { Pagination } from "antd";
const Table_Buttons = ({
  setcurrentPage,
  currentPage,

  totalData,
  listPerpage,
  handleRowsPerPageChange,
}) => {
  const handlePageChange = (page) => {
    setcurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    handleRowsPerPageChange(size);
  };

  return (
    <div className="pagination-container">
      <Pagination
        total={totalData}
        current={currentPage}
        pageSize={listPerpage}
        showSizeChanger
        pageSizeOptions={["10", "50", "100"]}
        onChange={handlePageChange}
        onShowSizeChange={handleSizeChange}
        select={false}
        className="custom-pagination"
      />
    </div>
  );
};

export default Table_Buttons;
