import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://inplay-api.nakshtech.info/",
});

const initialState = {
  betaData: [],
  isLoading: false,
  error: null,
};

export const fetchBetaWalletData = createAsyncThunk(
  "betaWallet/fetchbetaData",
  async ({ id, token }, { rejectWithValue }) => {

    // console.log("id",id,token)
    try {
      const res = await API.post(
        "/getbetawallet",
        { username: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("res", res);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const betWalletSlice = createSlice({
  name: "betaData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBetaWalletData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBetaWalletData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.betaData = action.payload; 
        state.error = null;
      })
      .addCase(fetchBetaWalletData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectBetaWalletInfo = (state) => state.betawallet.betaData;

export default betWalletSlice.reducer;
