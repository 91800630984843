import React, { memo, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function SessionTable({
  tabData,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setDataValue,
  setcountryName,
  setrealtimeData,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { sessionTeams } = useSelector((state) => state.sessionStack);
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);

  const handleClick = (
    betDetails,
    bettype,
    marketType,
    countryName,
    betvalue
  ) => {
    handleModal(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    setcountryName(countryName);
    setDataValue(betvalue);
    setrealtimeData(true);
  };

  return (
    <div className="betting-team-info_1">
      <div className="row">
        <div className="col-7 betting-t-n py-2">
          <p className="ms-2 mb-0 d-flex ">{tabData.RunnerName}</p>
          {sessionTeams.map((ele, index) => (
            <p
              key={index}
              className={`ms-5 
               ${ele.amount >= 0 ? "success-1" : "zero"}`
              }
            >
              {" "}
              {tabData.RunnerName == ele.team && ele.amount}
            </p>
          ))}
        </div>
        <div className="col-5 d-flex pos_re">
          {(tabData.GameStatus === "SUSPENDED" ||
            tabData.GameStatus === "Ball Running") && (
            <div className="tr_data_1">
              <button className="spnd">{tabData.GameStatus}</button>
            </div>
          )}
        
          {tabData.LayPrice1 !== 0.0 ? (
            <div
              className=" orange-bet-btn mb-0"
              onClick={() =>
                handleClick(
                  {
                    type: "Session Market",
                    team: tabData.RunnerName,
                    back: "0",
                    lay: tabData.LayPrice1,
                    BackSize1: "",
                    LaySize1: tabData.LaySize1,
                    min: "",
                    max: "",
                    matchid: id,
                    ballsess: "0",
                    sid: tabData.SelectionId,
                    srno: tabData.srno,
                    WinPerc: "0",
                    LayPrice2: tabData.LayPrice1,
                    LaySize2: tabData.LaySize1,
                    BackPrice2: tabData.BackPrice1,
                    BackSize2: tabData.BackSize1,
                    GameStatus: tabData.GameStatus,
                    gtype: "Session Market",
                    rem: "",
                    eventID: eventTypeid,
                  },
                  "lay",
                  "Session Market",
                  tabData.RunnerName,
                  {
                    valueOf: tabData.LayPrice1,
                    team: tabData.RunnerName,
                    sid: tabData.SelectionId,
                    status: tabData.GameStatus,
                    sid: tabData.SelectionId,
                  }
                )
              }
            >
              <div className="d-flex flex-column align-items-center">
                {Math.round(tabData.LayPrice1)}
                <span> {Math.round(tabData.LaySize1)}</span>
              </div>
            </div>
          ) : (
            <div className=" orange-bet-btn mb-0">
              <div className="d-flex flex-column align-items-center">
                0.0
                <span> 0.0</span>
              </div>
            </div>
          )}


{tabData.BackPrice1 != 0.0 ? (
            <div
              className="blue-bet-btn mb-0 ms-1"
              onClick={() =>
                handleClick(
                  {
                    type: "Session Market",
                    team: tabData.RunnerName,
                    back: tabData.BackPrice1,
                    BackSize1: tabData.BackSize1,
                    lay: "0",
                    LaySize1: "",
                    min: "",
                    max: "",
                    lay: "0",
                    matchid: id,
                    ballsess: "0",
                    sid: tabData.SelectionId,
                    srno: "0",
                    WinPerc: "0",
                    LayPrice2: tabData.LayPrice1,
                    LaySize2: tabData.LaySize1,
                    BackPrice2: tabData.BackPrice1,
                    BackSize2: tabData.BackSize1,
                    GameStatus: tabData.GameStatus,
                    gtype: "Session Market",
                    rem: "",
                    eventID: eventTypeid,
                  },
                  "back",
                  "Session Market",
                  tabData.RunnerName,
                  {
                    valueOf: tabData.BackPrice1,
                    team: tabData.RunnerName,
                    sid: tabData.SelectionId,
                    status: tabData.GameStatus,
                  }
                )
              }
            >
              <div className="d-flex flex-column align-items-center">
                {Math.round(tabData.BackPrice1)}
                <span> {Math.round(tabData.BackSize1)}</span>
              </div>
            </div>
          ) : (
            <div className=" blue-bet-btn ms-1 mb-0">
              <div className="d-flex flex-column align-items-center">
                0.0
                <span> 0.0</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(SessionTable);
