import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://inplay-api.nakshtech.info/",
});

export const LastBetDetails = createAsyncThunk(
  "user/lastbet",
  async ({ userId, id, token },{rejectWithValue}) => {
    try {
      const response = await API.post(
        "/getCurrentMatchOddData",
        {
          uid: userId,
          matchid: id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("Lastbet Res", response);
      return response.data.data;
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const lastbetSlice = createSlice({
  name: "lastbet",
  initialState: {
    lastbet: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LastBetDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LastBetDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.lastbet = action.payload;
      })
      .addCase(LastBetDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ? action.error.message : "Unknown error";
      });
  },
});

export default lastbetSlice.reducer;
