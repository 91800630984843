import React, { memo, useEffect, useState } from "react";

function SessionTable() {
  return (
    <div className="betting-team-info_1">
      <div className="row mt-2">
        <div className="col-7 betting-t-n py-2">
          <p className="ms-2 mb-0 d-flex ">
            BJP Will Win Total Seats In LS 2024
          </p>
        </div>
        <div className="col-5 d-flex pos_re">
          <div className=" orange-bet-btn mb-0">
            <div className="d-flex flex-column align-items-center">
              317
              <span className="sizefont">319</span>
            </div>
            
          </div>

          <div className=" blue-bet-btn ms-1 mb-0">
            <div className="d-flex flex-column align-items-center">
              317
              <span className="sizefont">319</span>
            </div>
            
          </div>
        </div>
        {/* <div className="col-2 d-flex pos_re">
          <div className="">
            <p className="text-center">
           <span> Min: 100</span><br></br>
           <span> Max: 100K</span>
            </p>
          </div>
        </div> */}
        
        
      </div>
      
      <div className="row">
        <div className="col-7 betting-t-n py-2">
          <p className="ms-2 mb-0 d-flex ">
          Congress Will Win Total Seats In LS 2024
          </p>
        </div>
        <div className="col-5 d-flex pos_re">
          <div className=" orange-bet-btn mb-0">
            <div className="d-flex flex-column align-items-center">
              46
              <span>48</span>
            </div>
          </div>
          <div className=" blue-bet-btn ms-1 mb-0">
            <div className="d-flex flex-column align-items-center">
              317
              <span className="sizefont">319</span>
            </div>
            
          </div>
        </div>

        {/* <div className="col-2 d-flex pos_re">
          <div className="">
            <p className="text-center">
           <span> Min: 100</span><br></br>
           <span> Max: 100K</span>
            </p>
          </div>
        </div> */}
        
      </div>

    </div>
  );
}

export default SessionTable;
