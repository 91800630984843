import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserWithdrawlHistory } from "../api/withdrawHistoryAPI";

const initialState = {
  withdrawHistory: [],
  isLoading: false,
  invalidToken: false, 
  error: null,
};

export const fetchUserWithdrawlHistoryData = createAsyncThunk(
  "withdrawHistory/fetchUserWithdrawlHistoryData",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const data = await fetchUserWithdrawlHistory(id, token);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const withdrawHistorySlice = createSlice({
  name: "withdrawHistory",
  initialState,
  reducers: {
    setInvalidToken: (state, action) => {
      state.invalidToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserWithdrawlHistoryData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserWithdrawlHistoryData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.withdrawHistory = action.payload;
        state.error = null;
      })
      .addCase(fetchUserWithdrawlHistoryData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload?.message === "Invalid authentication token") {
          state.invalidToken = true;
        }
      });
  },
});

export const { setInvalidToken } = withdrawHistorySlice.actions;

export default withdrawHistorySlice.reducer;
