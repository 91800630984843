import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

export default function Timer({
  BetResult,
  BetResultLast,
  setBetStatus,
  betStatus,
}) {
  const [timer, setTimer] = useState(0);
  const SOCKET_URL = "https://roulette-timer-socket-api.nakshtech.info/";

  useEffect(() => {
    const RouletTimerSocket = io(SOCKET_URL);
    RouletTimerSocket.emit("RouletteTimer");

    RouletTimerSocket.on("RouletteTimer", (data) => {
      console.log("data", data);
      setTimer(data);
      if (data === 58) {
        BetResult();
        setBetStatus(false);
      }
      if(data===20){
        toast.warning("Betting Closed For This Round");

      }
      if (data < 20 && betStatus === false) {
        setBetStatus(true);
      } else if (betStatus === true) {
        setBetStatus(false);
      }

      if (data === 16) {
        BetResultLast();
      }
      if (data === 9) {
        BetResult();
      }

     
    });

    return () => {
      RouletTimerSocket.disconnect();
    };
  }, []);

  return (
    <>
      <span>Timer : {timer}</span>
    </>
  );
}
